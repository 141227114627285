import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './comonents/home/Home';
import Layouts from './comonents/layouts/Layouts';
import NotFound from './comonents/notFound/NotFound';
import Industries from './comonents/industries/Industries';
import IndustriesDetials from './comonents/industries/IndustriesDetials';

function App() {
     //scroll top onClick in the new page
     const location = useLocation();
     useEffect(() => {
       window.scroll({
         top: 0,
         behavior: 'smooth'
       });
     }, [location]);
  
  
  return (
    <Routes>
      <Route path="/" element={<Layouts />}>
        <Route index element={<Home />}></Route>
        <Route path="/industries" element={<Industries />} />
        <Route path="/industries/:id" element={<IndustriesDetials />} />

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
